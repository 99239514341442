<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <offer-form :offer="offer" :own="own" :accessible="accessible" :interaction="interaction">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </offer-form>
                </div>
            </div>
        </div>
        <div v-if="true === false" class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('projects::offers.relationships.billables').ucFirst() }}</h4>
                </div>
                <div class="card-body bg-light">
                    <div class="row" v-if="offer.id">
                        <div class="col-lg-12">
                            <billable-items :id="offer.id" field="offer_id" store="billableOffer"></billable-items>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import OfferForm from "../../../components/forms/offer-form.vue";
import BillableItems from "../../../components/elements/billable-items.vue";

export default {
    components: {BillableItems, OfferForm, CallbackButton},

    props: {
        own: {
            type: Boolean,
            default: false
        },

        accessible: {
            type: Boolean,
            default: false
        },

        interaction: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            offer: 'offer/item'
        }),

        title: function () {
            return this.offer.id ? this.$tc('projects::offers.offer', 2).ucFirst() + ' / ' + this.offer.name : this.$t('base.create_new_item', {item: this.$tc('projects::offers.offer', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/interactions/my-offers')}
        }
    },

    methods: {
        back: function () {
            this.$router.go(-1);
            // this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('offer/show', {id: this.$route.params.id, query: { with: ['billables', 'meetings', 'project']}})
        }

        this.$store.dispatch('project/all')
        this.$store.dispatch('meeting/own')
    },

    unmounted() {
        this.$store.dispatch('offer/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
